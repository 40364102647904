@tailwind base;
@tailwind components;
@tailwind utilities;

.btn{
    @apply py-2 px-4 rounded;
}

.btn-primary{
    @apply bg-blue-700 text-white;
}

.btn-primary:hover{
    @apply bg-blue-800;
}

.card{
    @apply rounded overflow-hidden shadow-lg;
}